import { apiLogin } from '@/api/app'

// State
const state = {
    config: {},
}

const getters = {
    config: state => state.config
}

// Mutations
const mutations = {
    setConfig(state, data) {
        // console.log(data)
        state.config = data
    }
}

// Actions
const actions = {
    
}

const user = {
    state,
    mutations,
    actions,
    getters
}

export default user
