import request from '@/plugins/axios'
import { terminal } from '@/utils/enum'

/**  
 * Author lgp@u91.top 
 * 社区电商系统 1.0.0
 */

// 登录
export const apiLogin = data => request.post('/api/login/account', {...data, terminal})

// 退出登录
export const apiLogout = () => request.post('/api/login/logout')



// 获取用户详情
export const apiUserCenter = params => request.get('/api/user/centre', {params})

// 获取配置信息
export const apiGetConfig = () => request.get('/api/config/getconfig')



/** E 帖子相关 **/
// 帖子列表
export const apiArticleLists = params => request.get('/api/article/lists', { params })

// 帖子详情
export const apiArticleDetail = params => request.get('/api/article/detail', {params})


// 帖子评论列表
export const apiArticleCommentsLists = params => request.get('/api/article/comments_lists', {params})


// 帖子回复
export const apiArticleCommentsReply = params => request.post('/api/article/comments_reply', params)


// 关注去掉帖子
export const apiHandleArticleCollect = params => request.post('/api/article/handleCollect', params)


// 添加关注操作
export const apiHandleFollow = params => request.post('/api/user_extend/handleUserFollow', params)


// 用户主页
export const apiUserHomeInfo = params => request.get('/api/user/home', { params })


// 获取服务协议
export const apiPolicyAgreement = (params) => request.get("/api/config/getPolicyAgreement", { params });
