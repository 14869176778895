import router from './router'
import store from './store'
import NProgress from 'nprogress'


const loginPath = '/login'
const defaultPath = '/'
const whiteList = ['login']
NProgress.configure({ showSpinner: false }) 

router.beforeEach((to, from, next) => {
    NProgress.start()
    // 设置文档title
    to.meta?.title && (document.title = to.meta.title)
    let { token } = to.query
    if(token) {
        store.commit('SET_TOKEN', token)
    }
    
    
    // // 判断当前页面路径是否与目标页面路径一致
    // if (to.path !== currentPagePath) {
    //     // 如果不一致，则跳转到目标页面
    //     router.push(to.path)
    // } else {
    //     // 如果一致，则更新当前页面路径
    //     currentPagePath = to.path
    //     next()
    // }
    token = token || store.getters.token;
    if (token) {
        if (to.path === loginPath) {
            next({ path: defaultPath })
        } else {
            next();
        }
    } else {
        next()
    }

})


router.afterEach(() => {
    NProgress.done()
})
