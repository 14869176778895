// 开发环境域名

// const host_development = 'http://192.168.1.9:91'
const host_development = 'https://www.yuzhoudaoyu.com'

export default {
    // 版本
    version: '1.4.1',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development
}


