import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        redirect: '/error/404',
    },
    {
        path: '/',
        name: 'index',
        meta: {
            title: '首页',
        },
        component: () => import('@/views/index/index'),
    },
    {
        path: '/user',
        name: 'user',
        meta: {
            title: '主页',
        },
        component: () => import('@/views/user/home'),
    },
    {
        path: '/search',
        name: 'search',
        meta: {
            title: '搜索',
        },
        component: () => import('@/views/index/search'),
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录',
        },
        component: () => import('@/views/account/login'),
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: '关于我们',
        },
        component: () => import('@/views/about/index'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
